<template>
  <v-container align-content-center class="main">
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.mdAndUp, 'subheading': $vuetify.breakpoint.smAndDown}"
    >
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.mdAndUp, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <a class="btntitle" href="#/courses" />
      </div>
    </div>
    <v-layout v-if="$vuetify.breakpoint.xlAndUp" justify-center>
      <v-card class="rounded-card" elevation="1" max-width="2400px">
        <edliy-content :my-style="style" />
      </v-card>
    </v-layout>
    <edliy-content v-else :my-style="{layout: ''}" />
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.mdAndUp, 'subheading': $vuetify.breakpoint.smAndDown}"
    >
      <a class="btntitle" href="#/courses" />
    </div>
    <divide-content :my-style="style" />
    <v-layout v-if="$vuetify.breakpoint.xlAndUp" justify-center>
      <v-card class="rounded-card" elevation="1" max-width="2400px">
        <topic-content :my-style="style" />
      </v-card>
    </v-layout>
  <!--
    <topic-content v-else :my-style="{layout: ''}" />
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.xlAndUp, 'subheading': $vuetify.breakpoint.smAndDown}"
    >
      <a class="btntitle" href="#/courses" />
    </div>
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.mdAndUp, 'subheading': $vuetify.breakpoint.smAndDown}"
    >
      <a class="btntitle" href="#/courses" />
    </div>
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.mdAndUp, 'subheading': $vuetify.breakpoint.smAndDown}"
    >
      <div class="font-weight-medium"
           :class="{'display-1': $vuetify.breakpoint.mdAndUp, 'subheading': $vuetify.breakpoint.smAndDown}"
      >
        <a class="btntitle" href="#/courses" />
      </div>
    </div> -->
  </v-container>
</template>
<script>
// Removed import FeaturedContent from './dash-0.vue'
import EdliyContent from './dash-1.vue'
import TopicContent from './dash-2.vue'
import DivideContent from '../Divider.vue'
//import YourContent from './Demo/dash-3.vue'
export default {
    name: 'DemoPage',
    components: {
    EdliyContent,
    TopicContent,
    DivideContent},
    data () {
      return {
        style: {layout: "margin: 2%"},
        headline: ' ',
             }
    },
    created ()  {
    this.$store.commit('navigation/resetState');
    this.$store.commit('navigation/changeTitle', 'Products & Services');
    this.$store.commit('navigation/replaceLeftArrow', true);
    this.$store.commit('navigation/toggleshowhome', false);
    this.$store.commit('navigation/replaceBrain', true);
    },
   metaInfo: {
          title:"Products & Services",
          meta: [
            {vmid:"description", name:"description", content: "Watch our visually interactive MagicGraphs in action"},
            {vmid:"keywords", name:"keywords", content: "Demo of visually interactive illustration in science, engineering and mathematics, STEM"},
            { vmid:"chars", charset:"utf-8"},
            { vmid:"viewport", name:"viewport", content:"width=device-width, initial-scale=1"}
                ],
          link: [{rel: "canonical", href: "https://edliy.com/demo"}]
        }
    }
</script>
<style lang="scss">
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
#jxgbox4a input {border-style:solid;border-radius:4px;background-color:#FEBD49}
.about-img {
    margin-left: 100px;
}
.reload{
position: relative;
top: -50px;
left: 30px;
}
.main{
max-width:1600px;
width:100%;
}
.subhead {
color: #903;
float: left;
font-family: Georgia;
font-size: 75px;
line-height: 60px;
padding-top: 4px;
padding-right: 8px;
}
.btnhead{
background-color: #4CAF50; /* Green */
border-style: solid;
border: 0px;
border-radius: 3.5px;
color: white;
padding: 5px 15px 5px 15px;
text-align: center;
text-decoration: none;
display: inline-block;
font-family: Oswald;
@include respond-to(less-smallest-screens) {font-size: 12px;}
@include respond-to(smallest-screens) {font-size: 14px;}
@include respond-to(small-screens) {font-size: 16px;}
@include respond-to(medium-screens) {font-size: 20px;}
@include respond-to(large-screens) {font-size: 25px;}
@include respond-to(largest-screens) {font-size: 30px;}
}
.btntitle{
border-style: solid;
border: 0px;
border-radius: 3.5px;
color: black;
padding: 5px 15px 5px 15px;
text-align: center;
text-decoration: none;
display: inline-block;
font-family: Oswald;
font-size :32px;
}
@include respond-to(less-smallest-screens) {
.size{
font-size:28px;
//font-weight: bold;
font-family:Oswald;
}
}
@include respond-to(smallest-screens) {
.size{
font-size:29px;
//font-weight: bold;
font-family:Oswald;
position:relative;
}
}
@include respond-to(small-screens) {
.size{
font-size:40px;
//font-weight: bold;
font-family:Oswald;
position:relative;
}
}
@include respond-to(medium-screens) {
.size{
font-size:62px;
font-weight:light;
font-family:Oswald;
position:relative;
}
}
@include respond-to(large-screens) {
.size{
font-size:56px;
font-weight: light;
font-family:Oswald;
position:relative;
}
}
@include respond-to(largest-screens) {
.size{
font-size:62px;
font-weight: light;
font-family:Oswald;
position:relative;
}
}
.imgL{
@include respond-to(less-smallest-screens) {width:32%;}
@include respond-to(smallest-screens) {width:37%;}
@include respond-to(small-screens) {width: 60%;}
@include respond-to(medium-screens) {width: 67%;}
@include respond-to(large-screens) {width: 84%;}
@include respond-to(largest-screens) {width: 100%;}
}
.imgK {
margin-left:auto;
margin-right:auto;
margin-top:auto;
margin-bottom:auto;
left:0;
@include respond-to(less-smallest-screens) {width:50vw; height:50vw;}
@include respond-to(smallest-screens) {width:50vw; height:50vw;}
@include respond-to(small-screens) {width:40vw; height:40vw;}
@include respond-to(medium-screens) {width:40vw; height:40vw;}
@include respond-to(large-screens) {width:24vw; height:24vw;}
@include respond-to(largest-screens) {width:24vw; height:24vw;}
}
.jsxbox {
@include respond-to(less-smallest-screens) {width:12vw; height:12vw;}
@include respond-to(smallest-screens) {width:12vw; height:12vw;}
@include respond-to(small-screens) {width:12vw; height:12vw;}
@include respond-to(medium-screens) {width:24vw; height:24vw;}
@include respond-to(large-screens) {width:24vw; height:24vw;}
@include respond-to(largest-screens) {width:24vw; height:24vw;}
}
.rounded-card{
    border-radius:10px;
    width:100%;
}
</style>
