<template>
  <div id="sc123">
    <div style="margin-top: 10px" />
    <v-layout :style="myStyle.layout"
              align-center
              justify-center
              row
              wrap
              :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
        class="font-weight-light subheader-content"
        :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
      >
        At <span class="edliy-e">&epsilon;</span>dliy, we offer a wide array of learning aids that range from visually active illustrations, virtual experiments, and self-guided test modules, to interactive charts and graphs.
        <br><br>
        <v-layout
          justify-center
          align-center
          class="mb-3 pa-2"
        >
          <v-flex
            xl4
            sm4
            md4
            lg4
            xl4
          >
            <v-layout justify-center align-center>
              <v-img
                src="/assets/eggOne.svg"
                max-height="40px"
                max-width="40px"
                contain
              />
            </v-layout>
            <v-layout class="mt-1" justify-center align-center>
              <h5> Build Concepts </h5>
            </v-layout>
          </v-flex>
          <v-flex
            xl4
            sm4
            md4
            lg4
            xl4
          >
            <v-layout justify-center align-center>
              <v-img
                src="/assets/eggTwo.svg"
                max-height="40px"
                max-width="40px"
                contain
              />
            </v-layout>
            <v-layout class="mt-1" justify-center align-center>
              <h5> Practice Problems </h5>
            </v-layout>
          </v-flex>
          <v-flex
            xl4
            sm4
            md4
            lg4
            xl4
          >
            <v-layout justify-center align-center>
              <v-img
                src="/assets/eggThree.svg"
                max-height="40px"
                max-width="40px"
                contain
              />
            </v-layout>
            <v-layout class="mt-1" justify-center align-center>
              <h5> Test Knowledge </h5>
            </v-layout>
          </v-flex>
        </v-layout>

        By actively engaging students in the learning process, our learning aids make learning fun, improve retention, and help students learn about six times more.
        <a class="icn" href="https://www.gse.harvard.edu/news/14/11/benefit-interactive-learning"> <i class="fa fa-external-link-square" /></a>
      </v-flex>
    </v-layout>
    <div style="margin-top: 20px" />
    <v-layout
      :style="myStyle.layout"
      align-center
      justify-center
      row
      wrap
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div
          class="font-weight-medium"
          :class="{'display-1 px-4': $vuetify.breakpoint.lgAndUp,'headline px-2': $vuetify.breakpoint.mdOnly, 'title px-0': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Oswald"> Stepwise | Master Concepts</span>
        </div>
        <div style="margin-top: 10px" />
        <div
          class="font-weight-light subheader-content"
          :class="{'headline px-4': $vuetify.breakpoint.lgAndUp, 'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
        >
          <span class="edliy-e">&epsilon;</span>dliy's stepwise helps students master complex concepts by breaking them down into simpler ideas.
          <br>
          <a href="https://edliy.com/gradesMath" class="btnLeft mt-3 mb-3">Start Learning!</a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
        justify-center
        align-center
      >
        <v-responsive class="mt-3"
                      :aspect-ratio="1"
                      align="center"
                      justify="center"
        >
          <iframe
            style="border:1.5px solid black; border-radius:7.5px;"
            src="https://www.youtube.com/embed/xStb3CsDzJ4"
            width="80%"
            height="80%"
          />
        </v-responsive>
        <v-divider class="mt-3 mb-3" />
      </v-flex>
    </v-layout>
    <v-layout
      :style="myStyle.layout"
      align-center
      row
      wrap
      reverse="$vuetify.breakpoint.mdAndUp"
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div
          class="font-weight-medium"
          :class="{'display-1 px-4': $vuetify.breakpoint.lgAndUp,'headline px-2': $vuetify.breakpoint.mdOnly, 'title px-0': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Oswald"> Virtual Lab | Learning by Doing </span>
        </div>
        <div style="margin-top: 10px" />
        <div
          class="font-weight-light subheader-content"
          :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
        >
          <span class="edliy-e">&epsilon;</span>dliy's virtual lab lets you conduct experiments and explore scientific concepts in a simulated environment.
          <br>
          <a href="https://edliy.com/gradesMath" class="btnLeft mt-3 mb-3">Try It Yourself!</a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <v-responsive class="mt-3"
                      align="center"
                      justify="center"
                      :aspect-ratio="1"
        >
          <iframe
            style="border:1.5px solid black; border-radius:7.5px;"
            src="https://www.youtube.com/embed/kE0Q4Nz4_-o"
            width="80%"
            height="80%"
          />
        </v-responsive>
        <v-divider class="mt-3 mb-3" />
      </v-flex>
    </v-layout>
    <v-layout
      :style="myStyle.layout"
      align-center
      row
      wrap
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div class="font-weight-medium" :class="{'display-1 px-4': $vuetify.breakpoint.lgAndUp,'headline px-2': $vuetify.breakpoint.mdOnly, 'title px-0': $vuetify.breakpoint.smAndDown}">
          <span style="font-family:Oswald"> ExamEasy | Test Your Knowledge</span>
        </div>
        <div style="margin-top: 10px" />
        <div class="font-weight-light subheader-content"
             :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
        >
          End exam anxiety with <span class="edliy-e">&epsilon;</span>dliy's ExamEasy. ExamEasy offers visually active test modules with built-in analytics and AI-powered adaptive engine.
          <br>
          <a href="https://edliy.com/gradesMath" class="btnLeft mt-3 mb-3"> See Our Apps in Action!</a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <v-responsive class="mt-3"
                      aspect-ratio="1"
                      align="center"
                      justify="center"
        >
          <iframe
            style="border:1.5px solid black; border-radius:7.5px;"
            src="https://youtube.com/embed/MbRc0Ur47Ww"
            width="80%"
            height="80%"
          />
        </v-responsive>
        <v-divider class="mt-3 mb-3" />
      </v-flex>
    </v-layout>
    <v-layout
      :style="myStyle.layout"
      align-center
      row
      wrap
      reverse="$vuetify.breakpoint.mdAndUp"
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <div
          class="font-weight-medium"
          :class="{'display-1 px-4': $vuetify.breakpoint.lgAndUp,'headline px-2': $vuetify.breakpoint.mdOnly, 'title px-0': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Oswald"> MagicGraphs | Interactive Graphs  </span>
        </div>
        <div style="margin-top: 10px" />
        <div
          class="font-weight-light subheader-content"
          :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
        >
          <span class="edliy-e">&epsilon;</span>dliy's interactive graphs help students visualize data, see patterns and trends, and explore relationships.
          <br>
          <a href="/gradesMath" class="btnLeft mt-3 mb-3">Try It Yourself!</a>
        </div>
      </v-flex>
      <v-flex
        xs12
        sm6
        md6
        lg6
        xl6
      >
        <v-responsive class="mt-3"
                      align="center"
                      justify="center"
                      :aspect-ratio="1"
        >
          <iframe
            style="border:1.5px solid black; border-radius:7.5px;"
            src="https://www.youtube.com/embed/6x5_rguP7cU"
            width="80%"
            height="80%"
          />
        </v-responsive>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  name: 'AboutContent',
  props: {
    myStyle: {
      type: Object,
        default: () => {}
    },
  },
  data () {
    return {
      style: {layout: "margin: 20px"},
    }
  },
}
</script>
