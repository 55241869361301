<template>
  <div id="subjectcard123">
    <v-layout
      :style="myStyle.layout"
      align-center
      justify-center
      :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
      >
        <div
          :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
        >
          <v-layout class="mt-4">
            <h1 style="text-align:start">
              Explore <span class="header-etsy">&epsilon;</span>dliy!
            </h1>
          </v-layout>
        </div>
      </v-flex>
    </v-layout>
    <div style="margin-top: 10px" />
    <v-layout :style="myStyle.layout"
              align-center
              justify-center
              row
              wrap
              :class="{'px-4': $vuetify.breakpoint.lgAndUp,'px-2': $vuetify.breakpoint.mdOnly, 'px-0': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
        class="font-weight-light subheader-content"
        :class="{'headline px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 px-2': $vuetify.breakpoint.mdOnly, 'body-2 px-0': $vuetify.breakpoint.smAndDown}"
      >
        Explore our library of more than 100 meticulously authored lessons on a variety of topics in Science, Engineering and Mathematics.
        Each lesson contains brilliantly designed visually interactive simulations to provide students with a truly experiential learning environment.
        <br>
        <br>
      </v-flex>
    </v-layout>
    <div style="margin-top: 10px" />
    <v-layout
      align-center
      justify-center
      row
      :class="{'headline mt-3 px-4': $vuetify.breakpoint.lgAndUp,'subtitle-1 mt-3 px-2': $vuetify.breakpoint.mdOnly, 'body-2 mt-3 px-1': $vuetify.breakpoint.smAndDown}"
    >
      <v-flex
        v-for="topic in topics"
        :key="topic.key"
        xs12
        sm12
        md12
        lg12
        xl12
      >
        <subtopic-card
          :headline="topic.headline"
          :description="topic.description"
          :img="topic.img"
          :path="topic.path"
        />
      </v-flex>
    </v-layout>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
import SubtopicCard from '../SubTopics.vue'
export default {
    name: 'TopicContent',
    components: {
    SubtopicCard
    },
    props: {
        myStyle: {
            type: Object,
            default: () => {}
        },},
    data () {
      return {
        style: {layout: "margin: 20px"},
        headline: ' ',
        topics: [
          {
            headline: "Physics", description: "",
            img: "/assets/Phys.svg", path: "/gradesPhy"
          },
          {
            headline: "Chemistry", description: "",
            img: "/assets/Chem.svg", path: "/gradesChem"
          },
          {
            headline: "Mathematics ", description: "",
            img: "/assets/classroom.svg", path: "/gradesMath"
          },
        /*  {
            headline: "Programming", description: "",
            img: "/assets/chip.svg", path: "/programming"
          },*/
          ]}
            },
}
</script>
